@import '../../../assets/styles/theme';

@keyframes DefaultPromotionBg {
  0% {
    background-color: $primary;
  }

  100% {
    background-color: $accent;
  }
}

.default-promotion {
  background-image: linear-gradient(
    to bottom right,
    transparent,
    transparentize(white, 0.58)
  );
  color: $bg;
  animation-name: DefaultPromotionBg;
  animation-duration: 7s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.default-promotion-logo {
  filter: drop-shadow(1px 0 3px transparentize(black, 0.86));
}
