/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
@-webkit-keyframes HeaderBlockClip {
  0% {
    -webkit-clip-path: ellipse(140% 100% at 60% 0px);
            clip-path: ellipse(140% 100% at 60% 0px); }
  100% {
    -webkit-clip-path: ellipse(140% 100% at 40% 0px);
            clip-path: ellipse(140% 100% at 40% 0px); } }
@keyframes HeaderBlockClip {
  0% {
    -webkit-clip-path: ellipse(140% 100% at 60% 0px);
            clip-path: ellipse(140% 100% at 60% 0px); }
  100% {
    -webkit-clip-path: ellipse(140% 100% at 40% 0px);
            clip-path: ellipse(140% 100% at 40% 0px); } }

.header {
  z-index: 7;
  position: -webkit-sticky;
  position: sticky;
  top: 0; }

.header-shade-area {
  -webkit-filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.07));
          filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.07)); }

.header-block {
  padding: 14.28571px 20px !important;
  background-image: linear-gradient(to top right, rgba(0, 200, 240, 0.03), transparent);
  background-size: 100% 100%;
  background-position: -100% 50%;
  box-shadow: none;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  -webkit-clip-path: ellipse(140% 100% at 50% 0px);
          clip-path: ellipse(140% 100% at 50% 0px);
  -webkit-animation-name: HeaderBlockClip;
          animation-name: HeaderBlockClip;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.header-logo {
  margin-top: -4px;
  margin-bottom: -8px;
  width: 30px;
  height: 30px; }

.header-separator {
  padding: 0 14.28571px;
  font-size: 20px;
  opacity: 0.07; }
  @media (max-width: 480px) {
    .header-separator {
      visibility: hidden;
      padding: 0 10px; } }

.header-wordmark {
  font-size: 20px;
  text-decoration: none !important; }

.header-nav-max-view {
  display: block; }

.header-nav-min-view {
  display: none; }

@media (max-width: 720px) {
  .header-nav-max-view {
    display: none; }
  .header-nav-min-view {
    display: block; } }

.header-nav-links {
  margin: -30px 0;
  padding-right: 10px;
  height: 60px;
  text-decoration: none !important; }
  .header-nav-links .flex {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: inherit; }
    .header-nav-links .flex .center {
      margin: auto;
      padding: 0 20px; }

.header-nav-btn {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
  padding: 0 !important;
  width: 48px !important;
  height: 48px !important;
  border: 0 !important;
  background-image: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #0078b4 !important; }

.header-nav-drop-vault {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 0;
  width: 0; }

.header-nav-drop .gerami-menu {
  max-height: 80vh; }

.header-nav-drop-items {
  padding: 20px !important;
  width: 280px !important; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.footer {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, transparent 70%); }

.footer-left,
.footer-right {
  width: 60px; }
  .footer-left a,
  .footer-left a:visited,
  .footer-right a,
  .footer-right a:visited {
    color: rgba(0, 0, 0, 0.58);
    text-decoration: none; }
    .footer-left a:active, .footer-left a:focus, .footer-left a:hover,
    .footer-left a:visited:active,
    .footer-left a:visited:focus,
    .footer-left a:visited:hover,
    .footer-right a:active,
    .footer-right a:focus,
    .footer-right a:hover,
    .footer-right a:visited:active,
    .footer-right a:visited:focus,
    .footer-right a:visited:hover {
      color: #0078b4;
      text-decoration: underline; }

.footer-right {
  text-align: right; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
@-webkit-keyframes DefaultPromotionBg {
  0% {
    background-color: #0078b4; }
  100% {
    background-color: #00c8f0; } }
@keyframes DefaultPromotionBg {
  0% {
    background-color: #0078b4; }
  100% {
    background-color: #00c8f0; } }

.default-promotion {
  background-image: linear-gradient(to bottom right, transparent, rgba(255, 255, 255, 0.42));
  color: white;
  -webkit-animation-name: DefaultPromotionBg;
          animation-name: DefaultPromotionBg;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.default-promotion-logo {
  -webkit-filter: drop-shadow(1px 0 3px rgba(0, 0, 0, 0.14));
          filter: drop-shadow(1px 0 3px rgba(0, 0, 0, 0.14)); }

@font-face {
  font-family: 'Kelal Wordmark';
  font-display: swap;
  src: local("Modern No. 20"), url(/static/media/MOD20.60d6c0a8.TTF) format("truetype"), local("serif"); }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.flex {
  display: -webkit-flex !important;
  display: flex !important; }

.block {
  display: block !important; }

.inline-block {
  display: inline-block !important; }

.flex-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important; }

.flex-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.flex-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important; }

.absolute {
  position: absolute !important; }

.fixed {
  position: fixed !important; }

.relative {
  position: relative !important; }

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.top {
  vertical-align: top !important; }

.middle {
  vertical-align: middle !important; }

.bottom {
  vertical-align: bottom !important; }

.baseline {
  vertical-align: baseline !important; }

.full-width {
  width: 100% !important; }

.half-width {
  width: 50% !important; }

.quarter-width {
  width: 25% !important; }

.no-width {
  width: 0 !important; }

.full-height {
  height: 100% !important; }

.half-height {
  height: 50% !important; }

.quarter-height {
  height: 25% !important; }

.no-height {
  height: 0 !important; }

.margin-none {
  margin: 0 !important; }

.margin-auto {
  margin: auto !important; }

.margin-small {
  margin: 5px !important; }

.margin-normal {
  margin: 10px !important; }

.margin-big {
  margin: 20px !important; }

.margin-very-big {
  margin: 40px !important; }

.margin-top-none {
  margin-top: 0 !important; }

.margin-top-auto {
  margin-top: auto !important; }

.margin-top-small {
  margin-top: 5px !important; }

.margin-top-normal {
  margin-top: 10px !important; }

.margin-top-big {
  margin-top: 20px !important; }

.margin-top-very-big {
  margin-top: 40px !important; }

.margin-right-none {
  margin-right: 0 !important; }

.margin-right-auto {
  margin-right: auto !important; }

.margin-right-small {
  margin-right: 5px !important; }

.margin-right-normal {
  margin-right: 10px !important; }

.margin-right-big {
  margin-right: 20px !important; }

.margin-right-very-big {
  margin-right: 40px !important; }

.margin-bottom-none {
  margin-bottom: 0 !important; }

.margin-bottom-auto {
  margin-bottom: auto !important; }

.margin-bottom-small {
  margin-bottom: 5px !important; }

.margin-bottom-normal {
  margin-bottom: 10px !important; }

.margin-bottom-big {
  margin-bottom: 20px !important; }

.margin-bottom-very-big {
  margin-bottom: 40px !important; }

.margin-left-none {
  margin-left: 0 !important; }

.margin-left-auto {
  margin-left: auto !important; }

.margin-left-small {
  margin-left: 5px !important; }

.margin-left-normal {
  margin-left: 10px !important; }

.margin-left-big {
  margin-left: 20px !important; }

.margin-left-very-big {
  margin-left: 40px !important; }

.margin-horizontal-none {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.margin-horizontal-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.margin-horizontal-small {
  margin-right: 5px !important;
  margin-left: 5px !important; }

.margin-horizontal-normal {
  margin-right: 10px !important;
  margin-left: 10px !important; }

.margin-horizontal-big {
  margin-right: 20px !important;
  margin-left: 20px !important; }

.margin-horizontal-very-big {
  margin-right: 40px !important;
  margin-left: 40px !important; }

.margin-vertical-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.margin-vertical-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.margin-vertical-small {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.margin-vertical-normal {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.margin-vertical-big {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.margin-vertical-very-big {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.padding-none {
  padding: 0 !important; }

.padding-small {
  padding: 5px !important; }

.padding-normal {
  padding: 10px !important; }

.padding-big {
  padding: 20px !important; }

.padding-very-big {
  padding: 40px !important; }

.padding-top-none {
  padding-top: 0 !important; }

.padding-top-small {
  padding-top: 5px !important; }

.padding-top-normal {
  padding-top: 10px !important; }

.padding-top-big {
  padding-top: 20px !important; }

.padding-top-very-big {
  padding-top: 40px !important; }

.padding-right-none {
  padding-right: 0 !important; }

.padding-right-small {
  padding-right: 5px !important; }

.padding-right-normal {
  padding-right: 10px !important; }

.padding-right-big {
  padding-right: 20px !important; }

.padding-right-very-big {
  padding-right: 40px !important; }

.padding-bottom-none {
  padding-bottom: 0 !important; }

.padding-bottom-small {
  padding-bottom: 5px !important; }

.padding-bottom-normal {
  padding-bottom: 10px !important; }

.padding-bottom-big {
  padding-bottom: 20px !important; }

.padding-bottom-very-big {
  padding-bottom: 40px !important; }

.padding-left-none {
  padding-left: 0 !important; }

.padding-left-small {
  padding-left: 5px !important; }

.padding-left-normal {
  padding-left: 10px !important; }

.padding-left-big {
  padding-left: 20px !important; }

.padding-left-very-big {
  padding-left: 40px !important; }

.padding-horizontal-none {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.padding-horizontal-small {
  padding-right: 5px !important;
  padding-left: 5px !important; }

.padding-horizontal-normal {
  padding-right: 10px !important;
  padding-left: 10px !important; }

.padding-horizontal-big {
  padding-right: 20px !important;
  padding-left: 20px !important; }

.padding-horizontal-very-big {
  padding-right: 40px !important;
  padding-left: 40px !important; }

.padding-vertical-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.padding-vertical-small {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.padding-vertical-normal {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.padding-vertical-big {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.padding-vertical-very-big {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.font-XXS {
  font-size: 10px !important; }

.font-XS {
  font-size: 12px !important; }

.font-S {
  font-size: 14px !important; }

.font-M {
  font-size: 16px !important; }

.font-L {
  font-size: 20px !important; }

.font-XL {
  font-size: 24px !important; }

.font-XXL {
  font-size: 28px !important; }

.font-X3L {
  font-size: 32px !important; }

.font-X4L {
  font-size: 36px !important; }

.font-X5L {
  font-size: 42px !important; }

.font-X6L {
  font-size: 48px !important; }

.font-X7L {
  font-size: 56px !important; }

.italic {
  font-style: italic !important; }

.light {
  font-weight: lighter !important; }

.bold {
  font-weight: bold !important; }

.left {
  text-align: left !important; }

.center {
  text-align: center !important; }

.right {
  text-align: right !important; }

.justify {
  text-align: justify !important; }

.bg-primary {
  background-color: rgba(0, 120, 180, 0.95) !important; }

.bg-accent {
  background-color: rgba(0, 200, 240, 0.95) !important; }

.bg-blackish {
  background-color: rgba(56, 56, 56, 0.95) !important; }

.bg-whitish {
  background-color: rgba(247, 247, 247, 0.95) !important; }

.bg-black {
  background-color: rgba(0, 0, 0, 0.95) !important; }

.bg-white {
  background-color: rgba(255, 255, 255, 0.95) !important; }

.fg-primary {
  color: rgba(0, 120, 180, 0.91) !important; }

.fg-accent {
  color: rgba(0, 200, 240, 0.91) !important; }

.fg-blackish {
  color: rgba(71, 71, 71, 0.86) !important; }

.fg-whitish {
  color: rgba(237, 237, 237, 0.72) !important; }

.fg-black {
  color: rgba(0, 0, 0, 0.91) !important; }

.fg-white {
  color: rgba(255, 255, 255, 0.91) !important; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  min-height: 100vh;
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  background-color: rgba(247, 247, 247, 0.95); }

code {
  display: inline-block;
  padding: 2.5px 10px;
  border-radius: 20px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f2f2f2; }

hr {
  display: block;
  height: 1px;
  width: 100%;
  border: 0;
  border-radius: 50%;
  outline: 0;
  background: #e8e8e8 linear-gradient(to bottom, #d6d6d6, #f2f2f2); }

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  padding: 0;
  font-weight: 700; }

h1,
h2,
h3 {
  margin: 0 0 10px; }

h4,
h5,
h6 {
  margin: 0 0 5px; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-accordion-container {
  position: relative; }

.gerami-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: inherit;
  height: inherit;
  display: -webkit-flex;
  display: flex; }

.gerami-modal-img {
  position: relative;
  background-size: cover;
  background: no-repeat center center;
  height: inherit;
  transition: 1s all;
  display: inline-block; }
  .gerami-modal-img:hover {
    width: 100% !important; }
    .gerami-modal-img:hover .gerami-modal-caption-container {
      opacity: 1; }
  .gerami-modal-img .gerami-modal-caption-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15vh;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    transition: 0.75s all;
    opacity: 0; }
    .gerami-modal-img .gerami-modal-caption-container .gerami-modal-caption {
      font-size: 14px;
      text-align: center; }
    .gerami-modal-img .gerami-modal-caption-container .gerami-modal-action {
      position: absolute;
      bottom: 10px;
      right: 10px;
      float: right; }
      .gerami-modal-img .gerami-modal-caption-container .gerami-modal-action * {
        color: #00c8f0 !important; }
        .gerami-modal-img .gerami-modal-caption-container .gerami-modal-action *:hover {
          color: #fefefe !important; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-anchor {
  display: inline-block;
  text-decoration: underline;
  color: #006090;
  transition: all 150ms;
  cursor: pointer; }
  .gerami-anchor:active, .gerami-anchor:focus, .gerami-anchor:hover {
    color: #0078b4; }
  .gerami-anchor:active {
    -webkit-filter: opacity(0.84) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.09));
            filter: opacity(0.84) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.09));
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
    transition-delay: 0ms;
    transition-duration: 50ms; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-block {
  display: block;
  padding-right: 40px;
  padding-left: 40px; }

@media (max-width: 480px) {
  .gerami-block {
    padding-right: 20px;
    padding-left: 20px; } }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-button {
  display: inline-block;
  margin: 0;
  padding: 8px 18px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  outline: 0;
  font: inherit;
  text-align: center;
  text-decoration: none;
  background-color: #f7f7f7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  color: #474747;
  transition: all 150ms;
  cursor: pointer; }
  .gerami-button:focus, .gerami-button:hover {
    border-color: #0078b4;
    background-color: #ededed;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
    color: black; }
  .gerami-button:active {
    box-shadow: none;
    -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.09));
            filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.09));
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03); }
  .gerami-button:disabled, .gerami-button:disabled:active, .gerami-button:disabled:focus, .gerami-button:disabled:hover {
    border-color: transparent;
    background-color: #ededed;
    box-shadow: none;
    color: #6b6b6b;
    -webkit-filter: none;
            filter: none;
    -webkit-transform: none;
            transform: none;
    cursor: default; }

.gerami-button-primary {
  border-color: #0078b4;
  background-color: #0078b4;
  color: #ededed; }
  .gerami-button-primary:focus, .gerami-button-primary:hover {
    border-color: #0078b4;
    background-color: #0082c3;
    color: white; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-card {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07) !important;
  transition: all 150ms !important; }
  .gerami-card:active {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09) !important; }
  .gerami-card:focus, .gerami-card:hover {
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.09) !important; }

.gerami-card-img {
  height: 220px;
  background: #f7f7f7 center center;
  background-size: cover; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-checkbox {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer; }
  .gerami-checkbox:active > .gerami-checkbox-input ~ .gerami-checkbox-span {
    background-color: #00c8f0 !important;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
    transition-timing-function: ease-in-out;
    transition-duration: 90ms; }
  .gerami-checkbox:hover > .gerami-checkbox-input ~ .gerami-checkbox-span {
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
    background-color: #e6e6e6; }
  .gerami-checkbox:hover > .gerami-checkbox-input:checked ~ .gerami-checkbox-span {
    background-color: #00c8f0;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07); }
  .gerami-checkbox > .gerami-checkbox-input {
    display: none; }
    .gerami-checkbox > .gerami-checkbox-input:checked ~ .gerami-checkbox-span {
      background-color: #0078b4; }
  .gerami-checkbox > .gerami-checkbox-span {
    position: absolute;
    height: 28px;
    width: 28px;
    background-color: #ededed;
    border-radius: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    transition: all 300ms; }
    .gerami-checkbox > .gerami-checkbox-span:after {
      content: '';
      position: absolute;
      top: 8.33333px;
      left: 6.56757px;
      width: 14px;
      height: 5.6px;
      border: 0 solid #ededed;
      border-bottom-left-radius: 1px;
      border-bottom-width: 2px;
      border-left-width: 2px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }

.gerami-checkbox-child {
  margin: 3.33333px 3.33333px 3.33333px 38px; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-container {
  margin-right: -20px;
  margin-left: -20px; }

@media (max-width: 480px) {
  .gerami-container {
    margin-right: -10px;
    margin-left: -10px; } }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-content {
  margin: auto;
  width: 100%;
  overflow: hidden;
  text-align: left; }

.gerami-content-card {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.97);
  color: black;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.05); }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 7; }

.gerami-dialog-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  background-color: #fefefe;
  border-radius: 2px; }

.gerami-bottom {
  -webkit-animation: gerami-bottom-slid-in 1s ease-in 2s;
          animation: gerami-bottom-slid-in 1s ease-in 2s; }

@-webkit-keyframes gerami-bottom-slide-in {
  0% {
    top: 100%; }
  50% {
    top: 30%; }
  100% {
    top: 50%; } }

@keyframes gerami-bottom-slide-in {
  0% {
    top: 100%; }
  50% {
    top: 30%; }
  100% {
    top: 50%; } }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
@-webkit-keyframes fade-in {
  from {
    -webkit-filter: opacity(0);
            filter: opacity(0); }
  to {
    -webkit-filter: opacity(1);
            filter: opacity(1); } }
@keyframes fade-in {
  from {
    -webkit-filter: opacity(0);
            filter: opacity(0); }
  to {
    -webkit-filter: opacity(1);
            filter: opacity(1); } }

@-webkit-keyframes slide-in-from-left {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slide-in-from-left {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes slide-in-from-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slide-in-from-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.gerami-drawer-container {
  position: fixed;
  z-index: 7; }

.gerami-drawer-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.58);
  -webkit-animation: fade-in 150ms linear 0ms 1 forwards;
          animation: fade-in 150ms linear 0ms 1 forwards; }

.gerami-drawer {
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
  border-radius: 0;
  width: 84%; }

.gerami-drawer-animate-left {
  -webkit-animation: fade-in 150ms linear 0ms 1 forwards, slide-in-from-left 200ms linear 0ms 1 forwards;
          animation: fade-in 150ms linear 0ms 1 forwards, slide-in-from-left 200ms linear 0ms 1 forwards; }

.gerami-drawer-animate-right {
  -webkit-animation: fade-in 150ms linear 0ms 1 forwards, slide-in-from-right 200ms linear 0ms 1 forwards;
          animation: fade-in 150ms linear 0ms 1 forwards, slide-in-from-right 200ms linear 0ms 1 forwards; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-flex {
  display: -webkit-flex;
  display: flex; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-flex-spacer {
  -webkit-flex: auto;
          flex: auto; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-image {
  display: inline-block;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-imageInput-none {
  display: none; }

.gerami-imageInput-image-input:hover {
  opacity: 0.6; }

.gerami-imageInput-Camera-Image {
  display: inline-block; }

.gerami-imageInput-image {
  border-radius: 50%; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-input {
  display: inline-block;
  margin: 0;
  padding: 2px 0 1px;
  width: inherit;
  max-width: inherit;
  min-width: inherit;
  height: inherit;
  max-height: inherit;
  min-height: inherit;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dbdbdb;
  outline: 0;
  font: inherit;
  background-color: transparent;
  color: #474747;
  box-shadow: none;
  transition: all 200ms ease; }
  .gerami-input:active, .gerami-input:focus, .gerami-input:hover {
    background-color: white;
    border-color: #0078b4;
    color: black; }
  .gerami-input:disabled {
    border-color: #dbdbdb;
    background-color: #f7f7f7;
    box-shadow: none;
    color: #6b6b6b;
    -webkit-filter: none;
            filter: none;
    -webkit-transform: none;
            transform: none;
    cursor: default; }

.gerami-input-label {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 10px 0 0; }

.gerami-input-placeholder {
  position: absolute;
  top: 12px;
  left: 0;
  font: inherit;
  color: #8f8f8f;
  transition: all 140ms ease;
  cursor: text;
  pointer-events: none; }

.gerami-input-float {
  top: 0;
  font-size: 12px;
  line-height: 12px;
  color: #0078b4; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  to {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg); } }
@keyframes spinner {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  to {
    -webkit-transform: rotateZ(360deg);
            transform: rotateZ(360deg); } }

.gerami-loading {
  padding: 40px;
  text-align: center; }

.gerami-svg {
  display: inline-block;
  -webkit-animation: spinner 700ms linear 0ms infinite forwards;
          animation: spinner 700ms linear 0ms infinite forwards; }

.gerami-big-circle {
  fill: none;
  stroke: rgba(0, 0, 0, 0.09); }

.gerami-small-circle {
  fill: none;
  stroke: #0078b4; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
@keyframes fade-in {
  from {
    -webkit-filter: opacity(0);
            filter: opacity(0); }
  to {
    -webkit-filter: opacity(1);
            filter: opacity(1); } }

.gerami-menu-drop {
  position: absolute;
  z-index: 7; }

.gerami-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.44);
  -webkit-animation: fade-in 150ms linear 0ms 1 forwards;
          animation: fade-in 150ms linear 0ms 1 forwards; }

.gerami-menu {
  position: relative;
  padding: 10px 0;
  width: auto;
  width: initial;
  min-width: 240px;
  overflow-y: auto;
  text-align: left;
  -webkit-animation: fade-in 200ms linear 0ms 1 forwards;
          animation: fade-in 200ms linear 0ms 1 forwards; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-menu-item {
  display: block;
  padding: 10px 20px;
  width: auto;
  width: initial;
  font-weight: 400;
  background-color: transparent;
  color: #474747;
  cursor: pointer; }

.gerami-menu-item:hover,
.gerami-menu-item:focus,
.gerami-menu-item:active {
  background-color: #dbdbdb; }

.gerami-menu-item > button {
  padding: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  font: inherit !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: inherit !important;
  cursor: inherit !important; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-page {
  padding-right: 20px;
  padding-left: 20px; }

@media (max-width: 480px) {
  .gerami-page {
    padding-right: 10px;
    padding-left: 10px; } }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-radio {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the default radio button */
.gerami-radio input {
  display: none; }

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%; }

.gerami-radio:hover input ~ .checkmark {
  background-color: #ccc; }

.gerami-radio input:checked ~ .checkmark {
  background-color: #0078b4;
  -webkit-animation-name: radio;
          animation-name: radio;
  -webkit-animation-duration: 2s;
          animation-duration: 2s; }

.gerami-radio:hover input:checked ~ .checkmark {
  background-color: orange; }

@-webkit-keyframes radio {
  0% {
    background: blue;
    box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19); }
  100% {
    background-color: #0078b4; } }

@keyframes radio {
  0% {
    background: blue;
    box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19); }
  100% {
    background-color: #0078b4; } }

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  display: none; }

/* Show the indicator (dot/circle) when checked */
.gerami-radio input:checked ~ .checkmark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.gerami-radio .checkmark:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-range {
  position: relative;
  height: 0;
  text-align: left; }

.gerami-range-background-line {
  display: inline-block;
  position: absolute;
  float: left;
  width: 100%;
  height: 7px;
  border-radius: 20px;
  background-color: #dbdbdb;
  cursor: pointer; }

.gerami-range-selected-line {
  display: inline-block;
  position: absolute;
  float: left;
  margin-top: 0px;
  height: 7px;
  border-radius: 20px;
  background-color: #00c8f0;
  transition: all 150ms;
  cursor: pointer; }

.gerami-range-btn-vault {
  display: inline-block;
  position: absolute;
  float: left;
  transition: all 150ms; }

.gerami-range-btn {
  display: inline-block;
  position: absolute;
  float: left;
  margin-top: -9px;
  margin-left: -9px;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #0078b4;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  transition: all 150ms; }

.gerami-range-btn:active {
  background-color: #006ea5;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.05);
  -webkit-transform: scale3d(1.03, 1.03, 1.03);
          transform: scale3d(1.03, 1.03, 1.03);
  -webkit-filter: opacity(0.84) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.09));
          filter: opacity(0.84) drop-shadow(0 1px 3px rgba(0, 0, 0, 0.09));
  cursor: pointer; }

.gerami-range-full-filled-ghost {
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-select-container {
  min-width: 230px;
  z-index: 1; }

.gerami-select-header {
  border: 1px solid #0078b4;
  width: inherit;
  height: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 7px;
  border-radius: 20px; }

.gerami-select-placeholder {
  -webkit-flex: 80% 1;
          flex: 80% 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  color: #404040; }

.gerami-selected-options-container {
  margin-left: 9px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.gerami-multi-option-container {
  margin: 2px;
  background-color: #ededed;
  z-index: 1; }

.gerami-multi-option > i {
  margin-left: 7px; }

.gerami-multi-option > i:hover {
  color: #f0465a;
  cursor: pointer; }

.gerami-single-option-container,
.gerami-multi-option-container {
  padding: 4px;
  font-size: 13px; }

.gerami-arrow-container {
  min-width: 24px;
  height: 24px;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  cursor: pointer; }

.gerami-arrow-container i {
  font-size: 14px; }
  .gerami-arrow-container i:hover {
    color: #0078b4; }

.gerami-arrow-container .gerami-cancel-all {
  z-index: 1; }
  .gerami-arrow-container .gerami-cancel-all:hover {
    color: #f0465a; }

.gerami-arrow-container > i {
  margin: 2px;
  -webkit-align-self: center;
          align-self: center; }

.gerami-options-container-big {
  position: relative;
  width: inherit !important;
  min-width: inherit !important;
  background-color: black; }

.gerami-options-container {
  z-index: 99;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background-color: white;
  position: absolute;
  min-width: inherit !important;
  width: 100% !important; }

.gerami-options-container > div {
  padding: 7px; }

.gerami-options-container > div:hover {
  background-color: #ededed;
  color: #0078b4;
  cursor: pointer; }

.gerami-options-container > div > span {
  padding: 7px; }

.gerami-background-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.mySlides {
  display: none; }

img {
  vertical-align: middle; }

.gerami-slideShow-slides {
  background: #f7f7f7 center center;
  background-size: cover; }

/* Slideshow container */
.gerami-slideShow-SlideShowContainer {
  max-width: 100%;
  position: relative;
  margin: auto; }

/* Next & previous buttons */
.gerami-slideShow-prev,
.gerami-slideShow-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0; }

.gerami-slideShow-next {
  right: 0;
  border-radius: 3px 0 0 3px; }

.gerami-slideShow-prev {
  left: 0;
  border-radius: 3px 0 0 3px; }

.gerami-slideShow-prev:hover,
.gerami-slideShow-next:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.gerami-slideShow-text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center; }

.gerami-slideShow-dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #0078b4;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 1s ease-in; }

.gerami-slideShow-dot-style {
  opacity: 0.7;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center; }

.gerami-slideShow-active,
.gerami-slideShow-dot:hover {
  background-color: #717171; }

/* Fading animation */
.gerami-slideShow-fade {
  -webkit-animation: fade 3s ease-in;
          animation: fade 3s ease-in; }

@-webkit-keyframes fade {
  from {
    opacity: 0.2; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: 0.2; }
  to {
    opacity: 1; } }

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .gerami-slideShow-prev,
  .gerami-slideShow-next,
  .gerami-slideShow-text {
    font-size: 11px; } }

/* Other Animations */
.gerami-slideShow-invisible {
  display: none; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-textarea {
  display: inline-block;
  margin: 0;
  padding: 2px 0 1px;
  width: inherit;
  max-width: inherit;
  min-width: inherit;
  height: inherit;
  max-height: inherit;
  min-height: inherit;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dbdbdb;
  outline: 0;
  font: inherit;
  background-color: transparent;
  color: #474747;
  box-shadow: none;
  transition: all 200ms ease; }
  .gerami-textarea:active, .gerami-textarea:focus, .gerami-textarea:hover {
    background-color: white;
    border-color: #0078b4;
    color: black; }
  .gerami-textarea:disabled {
    border-color: #dbdbdb;
    background-color: #f7f7f7;
    box-shadow: none;
    color: #6b6b6b;
    -webkit-filter: none;
            filter: none;
    -webkit-transform: none;
            transform: none;
    cursor: default; }

.gerami-textarea-label {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 10px 0 0; }

.gerami-textarea-placeholder {
  position: absolute;
  top: 12px;
  left: 0;
  font: inherit;
  color: #8f8f8f;
  transition: all 140ms ease;
  cursor: text;
  pointer-events: none; }

.gerami-textarea-float {
  top: 0;
  font-size: 12px;
  line-height: 12px;
  color: #0078b4; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-title-subtitle {
  font-size: 12px; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-toggle-switch input {
  display: none; }

.gerami-toggle-left {
  height: 12px;
  width: 12px;
  background: #b0b0b0;
  display: inline-block;
  border-radius: 50%; }

.gerami-toggle-right {
  height: 12px;
  width: 12px;
  background: #b0b0b0;
  display: inline-block;
  border-radius: 50%; }

.gerami-toggle-span {
  width: 12px;
  height: 12px;
  background-color: #b0b0b0;
  cursor: pointer;
  margin: 0px -6px 0px -6px;
  display: inline-block;
  transition: 0.4s; }

.gerami-toggle-span:before {
  position: absolute;
  border-radius: 100%;
  content: '';
  height: 15px;
  width: 15px;
  margin-top: -2px;
  margin-left: -10px;
  background-color: #fff;
  box-shadow: 1px 1px 1px grey;
  transition: 0.4s; }

.gerami-toggle-span-selected:before {
  background-color: #0078b4; }

input:checked + .gerami-toggle-span:before {
  -webkit-transform: translateX(18px);
          transform: translateX(18px); }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-warning {
  border: 1px solid #f0465a;
  text-align: center;
  background-color: #fbcad0 !important;
  color: black; }

/* STRUCTURE */
/* PADDING */
/* FONT */
/* COLORS */
/* EFFECTS */
.gerami-yoga-1, .gerami-yoga-2, .gerami-yoga-3, .gerami-yoga-4, .gerami-yoga-5, .gerami-yoga-6, .gerami-yoga-7, .gerami-yoga-8, .gerami-yoga-9 {
  display: inline-block;
  padding: 10px; }
  .gerami-yoga-1 > *, .gerami-yoga-2 > *, .gerami-yoga-3 > *, .gerami-yoga-4 > *, .gerami-yoga-5 > *, .gerami-yoga-6 > *, .gerami-yoga-7 > *, .gerami-yoga-8 > *, .gerami-yoga-9 > * {
    display: inline-block;
    width: 100%; }

.gerami-yoga-1 {
  width: 100%; }

.gerami-yoga-2 {
  width: 50%; }

@media (max-width: 480px) {
  .gerami-yoga-2 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-2 {
    width: Infinity; } }

.gerami-yoga-3 {
  width: 33.33333%; }

@media (max-width: 720px) {
  .gerami-yoga-3 {
    width: 50%; } }

@media (max-width: 480px) {
  .gerami-yoga-3 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-3 {
    width: Infinity; } }

.gerami-yoga-4 {
  width: 25%; }

@media (max-width: 960px) {
  .gerami-yoga-4 {
    width: 33.33333%; } }

@media (max-width: 720px) {
  .gerami-yoga-4 {
    width: 50%; } }

@media (max-width: 480px) {
  .gerami-yoga-4 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-4 {
    width: Infinity; } }

.gerami-yoga-5 {
  width: 20%; }

@media (max-width: 1200px) {
  .gerami-yoga-5 {
    width: 25%; } }

@media (max-width: 960px) {
  .gerami-yoga-5 {
    width: 33.33333%; } }

@media (max-width: 720px) {
  .gerami-yoga-5 {
    width: 50%; } }

@media (max-width: 480px) {
  .gerami-yoga-5 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-5 {
    width: Infinity; } }

.gerami-yoga-6 {
  width: 16.66667%; }

@media (max-width: 1440px) {
  .gerami-yoga-6 {
    width: 20%; } }

@media (max-width: 1200px) {
  .gerami-yoga-6 {
    width: 25%; } }

@media (max-width: 960px) {
  .gerami-yoga-6 {
    width: 33.33333%; } }

@media (max-width: 720px) {
  .gerami-yoga-6 {
    width: 50%; } }

@media (max-width: 480px) {
  .gerami-yoga-6 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-6 {
    width: Infinity; } }

.gerami-yoga-7 {
  width: 14.28571%; }

@media (max-width: 1680px) {
  .gerami-yoga-7 {
    width: 16.66667%; } }

@media (max-width: 1440px) {
  .gerami-yoga-7 {
    width: 20%; } }

@media (max-width: 1200px) {
  .gerami-yoga-7 {
    width: 25%; } }

@media (max-width: 960px) {
  .gerami-yoga-7 {
    width: 33.33333%; } }

@media (max-width: 720px) {
  .gerami-yoga-7 {
    width: 50%; } }

@media (max-width: 480px) {
  .gerami-yoga-7 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-7 {
    width: Infinity; } }

.gerami-yoga-8 {
  width: 12.5%; }

@media (max-width: 1920px) {
  .gerami-yoga-8 {
    width: 14.28571%; } }

@media (max-width: 1680px) {
  .gerami-yoga-8 {
    width: 16.66667%; } }

@media (max-width: 1440px) {
  .gerami-yoga-8 {
    width: 20%; } }

@media (max-width: 1200px) {
  .gerami-yoga-8 {
    width: 25%; } }

@media (max-width: 960px) {
  .gerami-yoga-8 {
    width: 33.33333%; } }

@media (max-width: 720px) {
  .gerami-yoga-8 {
    width: 50%; } }

@media (max-width: 480px) {
  .gerami-yoga-8 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-8 {
    width: Infinity; } }

.gerami-yoga-9 {
  width: 11.11111%; }

@media (max-width: 2160px) {
  .gerami-yoga-9 {
    width: 12.5%; } }

@media (max-width: 1920px) {
  .gerami-yoga-9 {
    width: 14.28571%; } }

@media (max-width: 1680px) {
  .gerami-yoga-9 {
    width: 16.66667%; } }

@media (max-width: 1440px) {
  .gerami-yoga-9 {
    width: 20%; } }

@media (max-width: 1200px) {
  .gerami-yoga-9 {
    width: 25%; } }

@media (max-width: 960px) {
  .gerami-yoga-9 {
    width: 33.33333%; } }

@media (max-width: 720px) {
  .gerami-yoga-9 {
    width: 50%; } }

@media (max-width: 480px) {
  .gerami-yoga-9 {
    width: 100%; } }

@media (max-width: 240px) {
  .gerami-yoga-9 {
    width: Infinity; } }

body {
  overflow-y: scroll;
  line-height: 1.5;
  background-image: linear-gradient(to top right, rgba(0, 120, 180, 0.1), rgba(255, 255, 255, 0.95)); }

.kelal-wordmark {
  font-family: 'Kelal Wordmark', serif;
  text-decoration: none !important;
  text-transform: lowercase;
  color: #8c8c8c !important; }
  .kelal-wordmark::before {
    content: 'kelal ';
    color: #00b478; }
  .kelal-wordmark::after {
    content: '.';
    color: #ff4b87; }

